import { FIELD_TYPES } from 'helpers';

export const getFields = ({ values, initialValues, isAdmin }) => [
	{
		initialInputValue: initialValues.options?.tam_nett_price,
		inputValue: values.options?.tam_nett_price,
		inputName: 'options.tam_nett_price',
		checkboxLabelText: 'company.form.extra_services.tam',
		checkboxName: 'options.is_tam',
		isOpen: values.options?.is_tam,
		disabled: !isAdmin,
	},
	{
		initialInputValue: initialValues.options?.extra_driver_nett_price,
		inputValue: values.options?.extra_driver_nett_price,
		inputName: 'options.extra_driver_nett_price',
		checkboxLabelText: 'company.form.extra_services.extra_driver',
		checkboxName: 'options.is_extra_driver',
		isOpen: values.options?.is_extra_driver,
		disabled: !isAdmin,
	},
	{
		initialInputValue: initialValues.options?.auth_out_ksa_nett_price,
		inputValue: values.options?.auth_out_ksa_nett_price,
		inputName: 'options.auth_out_ksa_nett_price',
		checkboxLabelText: 'company.form.extra_services.authorization',
		checkboxName: 'options.is_auth_out_ksa',
		isOpen: values.options?.is_auth_out_ksa,
		disabled: !isAdmin,
	},
];

export const CHECKBOX = {
	type: FIELD_TYPES.CHECKBOX,
	name: 'service.chauffeur_booking_only',
	label: 'common.fields.chauffeur_booking_only',
};
