import React from 'react';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
} from '@material-ui/core';
import { useFormState } from 'react-final-form';

// Import utils
import {
	useCommonFields,
	useProfile,
	useTranslations,
} from 'components/utilities';

// Import helpers
import { CHECKBOX, getFields } from './helpers';

// Import components
import { OneTimePaymentField } from 'components/elements';

const ExtraServices = () => {
	const { values, initialValues } = useFormState();
	const { renderFields } = useCommonFields();
	const { isAdmin } = useProfile();
	const { t } = useTranslations();

	const extraServicesFields = getFields({ values, initialValues, isAdmin });

	return (
		<Card>
			<CardHeader title={t('company.form.extra_services.title')} />
			<Divider />
			<CardContent>
				<Grid container>
					{extraServicesFields.map((field) => (
						<OneTimePaymentField key={field.checkboxName} {...field} />
					))}
					{renderFields(CHECKBOX)}
				</Grid>
			</CardContent>
		</Card>
	);
};

export default ExtraServices;
